
import React from "react"
import MetaData from '../../components/metaData'
import Header from '../../components/header';
import PageTracking from "../../components/PageTracking";
import Art from './art';

const Illustration = () => {

  return (
      <>
        <MetaData  page='illustration' />
        <Header visibleHome={true} />
        <PageTracking page='/illustration' />
        <Art />
      </>
  )}

export default Illustration;
